import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Button, message, Breadcrumb } from "antd";
import { Images } from "../assets";
import {
  MdDashboard,
  MdMenuOpen,
  MdOutlineSupervisedUserCircle,
} from "react-icons/md";
import { logout } from "../ApiServices/Apifun";
import { Footer } from "antd/es/layout/layout";
import { BookOutlined, HomeOutlined, LogoutOutlined } from "@ant-design/icons";
const { Header, Sider, Content } = Layout;
const BasicLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 600 ? setCollapsed(true) : setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    const stored = localStorage.getItem("selected");
    if (stored) {
      setSelectedMenu(stored);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("selected", selectedMenu);
  }, [selectedMenu]);
  function getlogout() {
    logout()
      .then((response) => {
        if (response.status) {
          localStorage.clear();
          navigate("/");
          message.success({ content: response.data.message });
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Layout>
      <Header
      // style={{
      //   display: "flex",
      //   alignItems: "center",
      // }}
      >
        <Menu
          className="flex justify-between "
          theme="dark"
          mode="horizontal"
          selectedKeys={[selectedMenu]}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        >
          <div>
            <img
              src={Images.whitebg}
              alt="logo"
              className="w-36 mt-0  h-16 p-2 "
            />
          </div>
          <div>
            <Menu.Item
              key="1"
              icon={<HomeOutlined />}
              onClick={() => {
                setSelectedMenu("1");
                navigate("/homePage");
              }}
            >
              Home
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<MdOutlineSupervisedUserCircle />}
              onClick={() => {
                setSelectedMenu("2");
                navigate("/userProfile");
              }}
            >
              Profile
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<BookOutlined />}
              onClick={() => {
                setSelectedMenu("3");
                navigate("/bills");
              }}
            >
              Bills
            </Menu.Item>
           
          </div>
          <div>
            <Menu.Item
              key="6"
              icon={<LogoutOutlined />}
              onClick={() => {
                setSelectedMenu("6");
                getlogout();
              }}
            >
              Log Out
            </Menu.Item>
          </div>
        </Menu>
      </Header>

      <Content
        className={`bg-rgbaprimary3 h-full p-30 ml-12 mr-12`}
        style={{
          padding: "0 48px",
        }}
      >
        {/* <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
          <Breadcrumb.Item>App</Breadcrumb.Item>
        </Breadcrumb> */}
        <div className="bg-white min-h-screen p-10 border-r-8 mt-8 ">
          {" "}
          {children}
        </div>
      </Content>
      {/* <Footer
        style={{
          textAlign: "center",
        }}
      >
        Ezulix Software Pvt Ltd. ©{new Date().getFullYear()} Created by Ant UED
      </Footer> */}
    </Layout>
  );
};

export default BasicLayout;
