import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { otpVerify, resendLogin, userLogin } from "../ApiServices/Apifun";
import { dispatchtoast, setLocalStorageItem } from "../Utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../assets";
import OTPInput from "react-otp-input";

const Login = () => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const location = useLocation();
  const type = location?.state?.type;

  const handleLogin = (val) => {
    setBtnLoading(true);
    userLogin(val)
      .then((res) => {
        setVerificationToken(res.verificationToken);
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  const handleResendVerify = (val) => {
    setBtnLoading(true);
    resendLogin(val)
      .then((res) => {
        setVerificationToken(res.verificationToken);
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  const handleVerifyOTP = (values) => {
    console.log(values);
    const payload = {
      verificationToken: verificationToken,
      mobile: values.mobile,
      otp: values.otp,
    };
    setBtnLoading(true);
    otpVerify(payload)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("login", true);
          navigate("/homePage");
          // message.success(res.message);
          dispatchtoast(res.message);
          setLocalStorageItem("userDetails", {
            _id: res._id,
            name: res.name,
            mobileNumber: res.mobileNumber,
            district: res.district,
            block: res.block,
            medium: res.medium,
            schoolType: res.schoolType,
            Accesstoken: res.Accesstoken,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex justify-center h-screen items-center">
        <div className="shadow-lg m-4 shadow-gray-400 rounded-md w-[400px] p-4">
          <div className="bg-primary rounded-md flex flex-col justify-center items-center">
            <img src={Images.whitebg} className="mt-4 w-28 h-8" />

            <p className="text-center text-white mb-4">Login</p>
          </div>
          <Form
            onFinish={
              verificationToken
                ? (val) => handleVerifyOTP(val)
                : type === "Resend"
                ? (val) => handleResendVerify(val)
                : (val) => handleLogin(val)
            }
            onFinishFailed={(err) => console.log(err)}
          >
            <Form.Item
              label="Mobile"
              labelCol={{ span: 24 }}
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "Enter mobile",
                },
              ]}
            >
              <Input name="mobile" placeholder="mobile" />
            </Form.Item>
            {verificationToken ? (
              <Form.Item
                name="otp"
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please input your OTP",
                  },
                  {
                    pattern: /^\d{6}$/,
                    message: "OTP must be 6 digits",
                  },
                ]}
              >
                <OTPInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span style={{ margin: "0 5px" }}>-</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    width: "100%",
                    height: "auto",
                    border: "2px  solid gray",
                    borderRadius: "5px",
                  }}
                />
              </Form.Item>
            ) : (
              ""
            )}
            <Form.Item>
              <Button
                loading={btnLoading}
                style={{ color: "white" }}
                htmlType="submit"
                className="w-full mt-2 bg-primary"
              >
                lOGIN
              </Button>
            </Form.Item>
            <p
              onClick={() => {
                setVerificationToken("");
                navigate("/", {
                  state: {
                    type: "Resend",
                  },
                });
              }}
              className="text-center m-0 font-semibold cursor-pointer"
            >
              Resend OTP
            </p>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
