import { Get, Patch, Post, Put, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};
export const otpVerify = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.otpVerify, param);
  return Post(url, data, false);
};
export const resendLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resendLogin, param);
  return Post(url, data, false);
};
export const logout = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.logout, param);
  return Post(url, data);
};
export const getProfileDetails = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.getProfileDetails, param);
  return Get(url);
};
export const editProfile = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.editProfile, param);
  return Put(url,data);
};
export const getSchoolDetails = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.getSchoolDetails, param);
  return Get(url);
};
export const addData = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addData, param);
  return Post(url, data);
};
export const editData = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.editData, param);
  return Post(url,data);
};
export const generatebill = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.generatebill, param);
  return Post(url,data);
};
export const getBillsView = ( param = "") => {
  const url = getAPIUrl(Globalendpoints.getBillsView, param);
  return Get(url);
};


// export const addData = (data, param = "") => {
//   const url = getAPIUrl(Globalendpoints.addData, param);
//   return Post(url,data);
// };
// export const addMidDayData = (data, param = "") => {
//   const url = getAPIUrl(Globalendpoints.addMidDayMeal, param);
//   return Post(url,data);
// };

// export const getMidDayMealData = ( param = "") => {
//   const url = getAPIUrl(Globalendpoints.getMidDayMeal, param);
//   return Get(url);
// };
// export const getParticularUserMidDayMealAdmin = ( param = "") => {
//   const url = getAPIUrl(Globalendpoints.getparticularUSerMidDaymeal, param);
//   return Get(url);
// };
// export const getParticularCommonDataAdmin = ( param = "") => {
//   const url = getAPIUrl(Globalendpoints.getparticularcommonData, param);
//   return Get(url);
// };
// export const editMidDayMeal = (data, param = "") => {
//   const url = getAPIUrl(Globalendpoints.editMidDayMeal, param);
//   return Put(url,data);
// };
// export const editData = (data, param = "") => {
//   const url = getAPIUrl(Globalendpoints.editData, param);
//   return Put(url,data);
// };