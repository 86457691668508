import { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import {
  Button,
  Collapse,
  DatePicker,
  Divider,
  Spin,
  Table,
  Tooltip,
} from "antd";
import {
  BookOutlined,
  EditOutlined,
  ExportOutlined,
  FilePdfOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { generatebill, getSchoolDetails } from "../../ApiServices/Apifun";
import { ExtractDate, getLocalStorageItem } from "../../Utils";
import GenerateBill from "./GenerateBill";
import AddRegister from "./AddRegister";
import html2pdf from "html2pdf.js";
import moment from "moment";
import "./bill.css";
import ReactToPrint from "react-to-print";
import { flatten } from "flat";
const Home = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [openDataModal, setOpenDataModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [primaryRegData, setPrimaryRegData] = useState([]);
  const [middleRegData, setMiddleRegData] = useState([]);
  const [billGenerated, setBillGeneratedKey] = useState(false);
  const [data, setData] = useState({});
  const [from, setFrom] = useState("");
  const [filledData, setFilledData] = useState({});
  const [totalPages, setTotalPages] = useState();
  const [response, setResponse] = useState({});
  const [alertMsg, setAlertMessage] = useState("");
  const [addModal, setAddModal] = useState(false);
  const userId = getLocalStorageItem("userDetails")?._id;
  const [selectedDate, setSelectedDate] = useState(null);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = moment().month(); // Get current month index (0-11)
  const currentYear = moment().year(); // Get current year
  const [selectedMonth, setSelectedMonth] = useState(monthNames[currentMonth]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [record, setRecord] = useState({});
  const [remainingRicePrimary, setremainingRicePrimary] = useState("");
  const [remainingRiceMiddle, setremainingRiceMiddle] = useState("");
  const handleDateChange = (date, dateString) => {
    if (date) {
      setSelectedMonth(monthNames[date.month()]);
      setSelectedYear(date.year());
      setSelectedDate(date);
    } else {
      setSelectedMonth(monthNames[currentMonth]);
      setSelectedYear(currentYear);
      setSelectedDate(null);
    }
  };
  useEffect(() => {
    SchoolDetails(currentPage);
  }, [currentPage, selectedDate]);

  function GenerateBillDetails() {
    setIsLoading(true);
    const payload = {
      month: selectedMonth,
      year: selectedYear,
    };
    generatebill(payload)
      .then((response) => {
        if (response.status) {
          setFilledData(response.data);
        } else {
          setAlertMessage(response.message);
        }
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }

  function SchoolDetails() {
    setIsLoading(true);
    getSchoolDetails(`${selectedMonth}&year=${selectedYear}&userId=${userId}`)
      .then((response) => {
        if (response.status) {
          const primaryData = response.data?.PrimaryClassRegister?.Data ?? [];
          const middleData = response.data?.MiddelClassRegister?.Data ?? [];
          setData(response?.data);
          setResponse(response?.data);
          setBillGeneratedKey(response.data?.isBillGenerated);
          setcurrentPage(response.data?.result?.currentPage);
          setTotalPages(response.data?.result?.totalPages);
          const praptChawalPrimary =
            response?.data?.PrimaryClassRegister?.prapt_chawal ?? 0;
          const avsheshChawaPrimary =
            response?.data?.PrimaryClassRegister?.avsheshChawa ?? 0;
          const usedRicePrimary = primaryData.reduce((acc, i) => {
            return acc + (Number(i.Used_Rice) || 0);
          }, 0);
          {
            !data?.PrimaryClassRegister?.Data
              ? setremainingRicePrimary(
                  praptChawalPrimary + avsheshChawaPrimary
                )
              : setremainingRicePrimary(
                  praptChawalPrimary + avsheshChawaPrimary - usedRicePrimary
                );
          }

          // setremainingRicePrimary(
          //   praptChawalPrimary + avsheshChawaPrimary - usedRicePrimary
          // );

          let newDataPrimary = [...primaryData];
          let a = [...newDataPrimary];
          const totalRowPrimary = a
            .flat()
            .map((i) => {
              let t = { ...i };
              delete t["id"];
              delete t["entry_date"];
              delete t["sno"];
              delete t["avsheshchawal"];
              return t;
            })
            .reduce((acc, arr, index) => {
              let data = Object.keys(a[0]);
              let temp2 = {};

              const excludeKeys = ["entry_date", "Remaining_Rice"];

              for (let index = 0; index < a.length; index++) {
                for (let key of data) {
                  if (excludeKeys.includes(key)) {
                    continue;
                  }

                  const keynext = a[index][key] ?? 0;
                  if (typeof keynext === "string") {
                    temp2[key] = keynext;
                    continue;
                  }
                  if (typeof keynext === "object" && keynext !== null) {
                    if (temp2[key] === undefined) {
                      temp2[key] = { quantity: 0, amount: 0 };
                    }
                    temp2[key].quantity += keynext.quantity ?? 0;
                    temp2[key].amount += keynext.amount ?? 0;
                  } else {
                    temp2[key] = (temp2[key] ?? 0) + keynext;
                  }
                }
              }
              excludeKeys.forEach((key) => delete temp2[key]);

              return temp2;
            }, {});

          setPrimaryRegData([
            ...newDataPrimary,
            { ...totalRowPrimary, classType: "PrimaryClass", last: true },
          ]);
          const praptChawalMiddle =
            response?.data?.MiddelClassRegister?.prapt_chawal ?? 0;
          const avsheshChawaMiddle =
            response?.data?.MiddelClassRegister?.avsheshChawa ?? 0;
          const usedRiceMiddle = middleData.reduce((acc, i) => {
            return acc + (Number(i.Used_Rice) || 0);
          }, 0);
          {
            !data?.MiddelClassRegister?.Data
              ? setremainingRiceMiddle(praptChawalMiddle + avsheshChawaMiddle)
              : setremainingRiceMiddle(
                  praptChawalMiddle + avsheshChawaMiddle - usedRiceMiddle
                );
          }
          // setremainingRiceMiddle(
          //   praptChawalMiddle + avsheshChawaMiddle - usedRiceMiddle
          // );
          let newDataMiddle = [...middleData];
          let b = [...newDataMiddle];
          const totalRowMiddle = b
            .flat()
            .map((i) => {
              let t = { ...i };
              delete t["id"];
              delete t["entry_date"];
              delete t["sno"];
              delete t["avsheshchawal"];
              return t;
            })
            .reduce((acc, arr, index) => {
              let data = Object.keys(b[0]);
              let temp2 = {};

              const excludeKeys = ["entry_date", "Remaining_Rice"];

              for (let index = 0; index < b.length; index++) {
                for (let key of data) {
                  if (excludeKeys.includes(key)) {
                    continue;
                  }

                  const keynext = b[index][key] ?? 0;
                  if (typeof keynext === "object" && keynext !== null) {
                    if (temp2[key] === undefined) {
                      temp2[key] = { quantity: 0, amount: 0 };
                    }
                    temp2[key].quantity += keynext.quantity ?? 0;
                    temp2[key].amount += keynext.amount ?? 0;
                  } else {
                    temp2[key] = (temp2[key] ?? 0) + keynext;
                  }
                }
              }
              excludeKeys.forEach((key) => delete temp2[key]);

              return temp2;
            }, {});
          setMiddleRegData([
            ...newDataMiddle,
            { ...totalRowMiddle, classType: "MiddelClass", last: true },
          ]);
        } else {
          setAlertMessage(response.message);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      })
      .finally(() => setIsLoading(false));
  }

  const componentRef = useRef();
  const flattenData = (data) => {
    return data.map((record) => flatten(record));
  };

  const exportToExcel = () => {
    const flattenedPrimaryData = flattenData(primaryRegData);
    const flattenedMiddleData = flattenData(middleRegData);

    const combinedData = [...flattenedPrimaryData, ...flattenedMiddleData];
    const worksheet = XLSX.utils.json_to_sheet(combinedData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Combined Data");
    XLSX.writeFile(workbook, "data.xlsx");
  };

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options).replace(/\//g, "-");
  };
  const commonColumns = [
    {
      title: "S.No.",
      key: "sno",
      dataIndex: "sno",
      className: "green-header text-xs w-8",
      render: (text, record, index) => (record.last ? "" : <p>{index + 1}</p>),
    },
    {
      title: "तारीख",
      dataIndex: "entry_date",
      key: "entry_date",
      className: "cell-colour green-header text-xs ",
      render: (text, record) =>
        record.last ? (
          "Total"
        ) : (
          <p className="text-xs w-18">{formatDate(record.entry_date)}</p>
        ),
    },
    {
      title: "उपस्थित छात्रों की संख्या",
      dataIndex: "No_of_students",
      key: "No_of_students",
      className: "cell-colour green-header text-xs w-8",
    },
    {
      title: "खपत्त चावल",
      dataIndex: "Used_Rice",
      key: "Used_Rice",
      className: "green-header text-xs w-8",
      render: (text, record) =>
        record.Used_Rice ? Number(text)?.toFixed(2) : "0.00",
    },
    {
      title: "अवशेष चावल",
      dataIndex: "Remaining_Rice",
      key: "Remaining_Rice",
      className: "green-header text-xs w-8",
      render: (text, record, index) => {
        const classType = String(record.classType);

        if (record.last) {
          if (classType === "PrimaryClass") {
            return remainingRicePrimary?.toFixed(2);
          } else if (classType === "MiddelClass") {
            return remainingRiceMiddle?.toFixed(2);
          }
        }
        return <p className="text-xs">{Number(text)?.toFixed(2) || "0.00"}</p>;
      },
    },

    {
      title: "आवर्तक व्यय",
      className: "orange-header ",
      children: [
        {
          title: "दाल",
          className: "blue-header",
          children: [
            {
              title: "मात्रा",
              dataIndex: "dal",
              key: "dal_quantity",

              render: (record) =>
                record ? Number(record?.quantity)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
            {
              title: "राशि",
              dataIndex: "dal",
              key: "dal_amount",
              render: (record) =>
                record ? Number(record?.amount)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
          ],
        },
        {
          title: "मसाला/नमक",
          className: "blue-header",
          children: [
            {
              title: "मात्रा",
              dataIndex: "Masala_Namak",
              key: "Masala_Namak_quantity",
              render: (record) =>
                record ? Number(record?.quantity)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
            {
              title: "राशि",
              dataIndex: "Masala_Namak",
              key: "Masala_Namak_amount",
              render: (record) =>
                record ? Number(record?.amount)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
          ],
        },
        {
          title: "सब्जी/सोयाबीन/सलाद",
          className: "blue-header",
          children: [
            {
              title: "मात्रा",
              dataIndex: "Sabji_Soyabean_salad",
              key: "Sabji_Soyabean_salad_quantity",
              render: (record) =>
                record ? Number(record?.quantity)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
            {
              title: "राशि",
              dataIndex: "Sabji_Soyabean_salad",
              key: "Sabji_Soyabean_salad_amount",
              render: (record) =>
                record ? Number(record?.amount)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
          ],
        },
        {
          title: "तेल",
          className: "blue-header",
          children: [
            {
              title: "मात्रा",
              dataIndex: "Tel",
              key: "Tel_quantity",
              render: (record) =>
                record ? Number(record?.quantity)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
            {
              title: "राशि",
              dataIndex: "Tel",
              key: "Tel_amount",
              render: (record) =>
                record ? Number(record?.amount)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
          ],
        },
        {
          title: "जलावन",
          className: "blue-header",
          children: [
            {
              title: "मात्रा",
              dataIndex: "Jalawan",
              key: "Jalawan_quantity",
              render: (record) =>
                record ? Number(record?.quantity)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
            {
              title: "राशि",
              dataIndex: "Jalawan",
              key: "Jalawan_amount",
              render: (record) =>
                record ? Number(record?.amount)?.toFixed(2) : "0.00",
              className: "blue-header text-xs w-8",
            },
          ],
        },
        {
          title: "कुल व्यय",
          dataIndex: "Total_expenses",
          key: "Total_expenses",
          render: (text) => (text ? Number(text)?.toFixed(2) : "0.00"),
          className: "blue-header text-xs w-8",
        },
      ],
    },
    {
      title: "मज़दूरी व्यय",
      dataIndex: "Labor_charge",
      key: "Labor_charge",
      render: (text) => (text ? Number(text)?.toFixed(2) : "0.00"),
      className: "green-header text-xs w-8",
    },
    {
      title: "अवशेष राशि",
      dataIndex: "Remaining_amount",
      key: "Remaining_amount",
      className: "green-header text-xs w-8",
      render: (text, record, index) => {
        if (record.last) {
          const previousIndexValue =
            index - 1 >= 0
              ? record.classType === "PrimaryClass"
                ? Number(primaryRegData[index - 1]?.Remaining_amount)?.toFixed(
                    2
                  )
                : Number(middleRegData[index - 1]?.Remaining_amount)?.toFixed(2)
              : "0.00";
          return (
            <p className="text-xs" key={index}>
              {previousIndexValue}
            </p>
          );
        } else {
          return (
            <p className="text-xs">{Number(text)?.toFixed(2) || "0.00"}</p>
          );
        }
      },
    },

    {
      title: "संपादन करें",
      dataIndex: "edit",
      key: "edit",
      className: "green-header text-xs hide-on-print w-8",
      render: (text, record) =>
        record.last ? (
          ""
        ) : (
          <p
            className=" text-xs cursor-pointer"
            onClick={() => {
              setAddModal(true);
              setFrom("edit");
              setRecord(record);
            }}
          >
            <EditOutlined />
          </p>
        ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            School Details
          </div>
          {alertMsg ? (
            <div className="bg-red-400 mb-2 relative overflow-hidden text-white font-bold p-2 text-lg">
              <p className="movingText inline-block relative">{alertMsg}</p>
            </div>
          ) : (
            <>
              <div>
                <div className="grid gap-14 items-center justify-between relative m-5 grid-cols-1 sm:grid-1 md:grid-cols-1 lg:grid-cols-4">
                  {billGenerated ? (
                    ""
                  ) : (
                    <>
                      <Button
                        className="responsive-button w-full"
                        onClick={() => {
                          setAddModal(true);
                          setFrom("add");
                          setRecord(data);
                        }}
                      >
                        <EditOutlined />
                        छात्र संख्या प्रविष्टि जोड़ें
                      </Button>
                      <Button
                        className="responsive-button"
                        onClick={() => {
                          setOpenDataModal(true);
                          setFrom("home");
                          GenerateBillDetails();
                        }}
                      >
                        <BookOutlined />
                        बिल जनरेट करें
                      </Button>
                    </>
                  )}
                  <Button className="responsive-button" onClick={exportToExcel}>
                    <ExportOutlined />
                  </Button>
                  <ReactToPrint
                    trigger={() => (
                      <Tooltip title="Please print this document in Landscape orientation.(Print > Layout > Landscape)">
                        <Button
                          icon={<PrinterOutlined />}
                          className="responsive-button w-full"
                        >
                          Print/ Pdf डाउनलोड करें
                        </Button>
                      </Tooltip>
                    )}
                    content={() => componentRef.current}
                  />
                </div>

                <div className="flex justify-end  relative m-5 ">
                  <DatePicker
                    picker="month"
                    placeholder="Select month"
                    onChange={handleDateChange}
                    value={selectedDate}
                  />
                </div>
              </div>

              <div
                className="register-container grid grid-cols-1 sm:grid-cols-1 w-full overflow-x-scroll"
                ref={componentRef}
              >
                <Divider className="text-sm font-semibold">
                  Primary Class Register
                </Divider>
                <Collapse size="small" className="overflow-x-auto">
                  <Collapse.Panel
                    header={
                      <div className="w-full">
                        <div className="bg-green-500 mb-2 text-white font-bold p-2 text-lg flex justify-center">
                          प्रधानमंत्री पोषण शक्ति निर्माण व्यय योजना विवरणी पंजी
                          (I-V)
                        </div>

                        <div className="overflow-x-auto">
                          <table className="min-w-full divide-y ">
                            <tbody>
                              {/* First Row */}
                              <tr>
                                <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                  स्कूल का नाम
                                </td>
                                <td className="px-3 py-1 text-left">
                                  <p>
                                    {getLocalStorageItem("userDetails").name}
                                  </p>
                                </td>
                                <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                  प्रखंड
                                </td>
                                <td className="px-3 py-1 text-left">
                                  {getLocalStorageItem("userDetails")?.block ??
                                    "--"}
                                </td>
                                <td className="px-3 py-1 text-left"></td>
                                <td className="px-3 py-1 text-left"></td>
                              </tr>

                              {/* Second Row */}
                              <tr>
                                <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                  माह का नाम
                                </td>
                                <td className="px-3 py-1 text-left">
                                  {selectedMonth ?? " --"}
                                </td>

                                <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                  जिला
                                </td>
                                <td className="px-3 py-1 text-left">
                                  {getLocalStorageItem("userDetails")
                                    ?.district ?? "--"}
                                </td>
                                <td className="px-3 py-1 text-left"></td>
                                <td className="px-3 py-1 text-left"></td>
                              </tr>

                              {/* Third Row */}
                              <tr>
                                <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                  अवशेष राशि
                                </td>
                                <td className="px-3 py-1 text-left">
                                  {response?.PrimaryClassRegister
                                    ?.avsheshRashi ?? "--"}
                                </td>
                              </tr>

                              {/* Fourth Row */}
                              <tr>
                                <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                  अवशेष खाद्यान (चावल)
                                </td>
                                <td className="px-3 py-1 text-left">
                                  <div className="flex justify-between">
                                    <p>
                                      {response?.PrimaryClassRegister
                                        ?.avsheshChawa ?? "--"}
                                    </p>
                                    <Divider
                                      className="h-5 border-gray-400"
                                      type="vertical"
                                    />
                                    <p>
                                      {response?.PrimaryClassRegister
                                        ?.prapt_chawal ?? "--"}
                                    </p>
                                    <Divider
                                      className="h-5 border-gray-400"
                                      type="vertical"
                                    />
                                    <p>
                                      {(
                                        (response?.PrimaryClassRegister
                                          ?.prapt_chawal ?? 0) +
                                        (response?.PrimaryClassRegister
                                          ?.avsheshChawa ?? 0)
                                      ).toFixed(2)}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                    key="1"
                  >
                    {" "}
                    <div className="overflow-x-auto h-[60%]">
                      <Table
                        columns={commonColumns}
                        size="small"
                        className="history-table animate-fade-in  mt-4 text-white rounded-lg custom-table-header"
                        bordered
                        pagination={false}
                        dataSource={primaryRegData}
                      />
                    </div>
                  </Collapse.Panel>
                </Collapse>

                {!response?.MiddelClassRegister ? (
                  ""
                ) : (
                  <>
                    <Divider className="text-sm font-semibold">
                      Middle Class Register
                    </Divider>
                    <Collapse size="small" className="overflow-x-auto">
                      <Collapse.Panel
                        header={
                          <div className="w-full">
                            <div className="bg-green-500 mb-2 text-white font-bold p-2 text-lg flex justify-center">
                              प्रधानमंत्री पोषण शक्ति निर्माण व्यय योजना विवरणी
                              पंजी (VI-VIII)
                            </div>
                            <div className="overflow-x-auto">
                              <table className="min-w-full divide-y ">
                                <tbody>
                                  {/* First Row */}
                                  <tr>
                                    <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                      स्कूल का नाम
                                    </td>
                                    <td className="px-3 py-1 text-left">
                                      {getLocalStorageItem("userDetails").name}
                                    </td>
                                    <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                      प्रखंड
                                    </td>
                                    <td className="px-3 py-1 text-left">
                                      {getLocalStorageItem("userDetails")
                                        ?.block ?? "--"}
                                    </td>
                                    <td className="px-3 py-1 text-left"></td>
                                    <td className="px-3 py-1 text-left"></td>
                                  </tr>

                                  {/* Second Row */}
                                  <tr>
                                    <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                      माह का नाम
                                    </td>
                                    <td className="px-3 py-1 text-left">
                                      {selectedMonth ?? " --"}
                                    </td>

                                    <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                      जिला
                                    </td>
                                    <td className="px-3 py-1 text-left">
                                      {getLocalStorageItem("userDetails")
                                        ?.district ?? "--"}
                                    </td>
                                    <td className="px-3 py-1 text-left"></td>
                                    <td className="px-3 py-1 text-left"></td>
                                  </tr>

                                  {/* Third Row */}
                                  <tr>
                                    <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                      अवशेष राशि
                                    </td>
                                    <td className="px-3 py-1 text-left">
                                      {response?.MiddelClassRegister
                                        ?.avsheshRashi ?? "--"}
                                    </td>
                                  </tr>

                                  {/* Fourth Row */}
                                  <tr>
                                    <td className="px-3 py-1 text-left  font-semibold  uppercase">
                                      अवशेष खाद्यान (चावल)
                                    </td>
                                    <td className="px-3 py-1 text-left">
                                      <div className="flex justify-between">
                                        <p>
                                          {response?.MiddelClassRegister
                                            ?.avsheshChawa ?? "--"}
                                        </p>
                                        <Divider
                                          className="h-5 border-gray-400"
                                          type="vertical"
                                        />
                                        <p>
                                          {response?.MiddelClassRegister
                                            ?.prapt_chawal ?? "--"}
                                        </p>
                                        <Divider
                                          className="h-5 border-gray-400"
                                          type="vertical"
                                        />
                                        <p>
                                          {(
                                            (response?.MiddelClassRegister
                                              ?.prapt_chawal ?? 0) +
                                            (response?.MiddelClassRegister
                                              ?.avsheshChawa ?? 0)
                                          ).toFixed(2)}
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                        key="1"
                      >
                        <div className="overflow-x-auto h-[60%]">
                          <Table
                            size="small"
                            className="history-table animate-fade-in  mt-4 text-white rounded-lg custom-table-header "
                            bordered
                            pagination={false}
                            dataSource={middleRegData}
                            columns={commonColumns}
                          ></Table>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}

      {openDataModal && (
        <GenerateBill
          from={from}
          filledData={filledData}
          open={openDataModal}
          setOpen={setOpenDataModal}
        />
      )}

      {addModal && (
        <AddRegister
          from={from}
          record={record}
          apiCall={SchoolDetails}
          open={addModal}
          setOpen={setAddModal}
        />
      )}
    </>
  );
};

export default Home;
