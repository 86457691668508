import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorageItem } from "../Utils";
import BasicLayout from "../AdminLayout";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("login");
    return user;
  };

  return isAuthenticated() ? (
    <>
      {" "}
      <BasicLayout>
        <Outlet/>
     </BasicLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
