
import Bills from "../Main/Bills";
import Home from "../Main/Home";
import Profile from "../Main/Profile";
import ProtectedAuth from "./PrivateAuth";

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/homePage",
          element: <Home />,
        },
        {
          path: "/userProfile",
          element: <Profile />,
        },
        {
          path: "/bills",
          element: <Bills />,
        },
        // {
        //   path: "/change-password",
        //   element: <ChangePassword />,
        // },

        // ERROR ROUTES ENDED
      ],
    },
  ];
};
