export const Authendpoint={
    Login:"/user/register-login",
    logout:`/user/logout`,
    otpVerify:`/user/verify-otp`,
    resendLogin:`/user/resend-otp`,
    changePassword:"user/changePassword",
    register:"user/register"
}
export const Globalendpoints={

    getSchoolDetails:`/common/getRegistersBySchool?month=`,
    getProfileDetails:"user/profile",
    editProfile:"user/UpdateProfile",
    addData:`/user/makeRegisterEntry`,
    editData:`/user/UpdateRegisterEntry`,
    generatebill:`/user/generateBill`,
    getBillsView:`/common/getBillsBySchool?schoolId=`,
}