import { BrowserRouter, HashRouter, useRoutes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerMsg } from "./Utils";
import "./App.css"
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";

function App() {
  const CasinoRouting = [ ...PrivateAuth(),...PublicRoutes()];
  const CasinoRouteconfig = () => useRoutes(CasinoRouting);
  return (
    <>
     <HashRouter>
          <CasinoRouteconfig />
      </HashRouter>
      <ToastContainerMsg />
    </>

    // <Login/>
    // <p>,qhdkqjhwdkjqwhdkjwhqd</p>
  );
}

export default App;
