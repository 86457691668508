import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { editProfile, getProfileDetails } from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const [form] = Form.useForm();
  const formRef = useRef(form);
  const [schoolType, setSchoolType] = useState();
  // const handleSchoolTypeChange = (value) => {
  //   setSchoolType(value);
  // };
  const ProfileDetailsView = () => {
    getProfileDetails()
      .then((res) => {
        const settingsData = res.data;
        setData(res.data);
        form.setFieldsValue({
          numberOfStudents: settingsData.numberOfStudents,
          schoolName: settingsData.schoolName,
          district: settingsData.district,
          block: settingsData.block,
          schoolType: settingsData.schoolType,
          medium: settingsData.medium,
          avsheshChawa: settingsData.avsheshChawa,
          mobileNumber: settingsData.mobileNumber,
          U_Dise_Code: settingsData.U_Dise_Code,
          AdharNo: settingsData.AdharNo,
          PAN: settingsData.PAN,
          MiddleClass_avsheshChawa: settingsData.MiddleClass_avsheshChawa,
          PrimaryClass_avsheshChawa: settingsData.PrimaryClass_avsheshChawa,
          userName: settingsData.userName,
        });
        setSchoolType(settingsData.schoolType);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    ProfileDetailsView();
  }, []);

  const editProfileDetails = (changedValues) => {
    const dataObj = { data: { ...changedValues } };

    editProfile(dataObj, data.id)
      .then((res) => {
        if (res.status) {
          // message.success(res.message);
          dispatchtoast(res.message);
          ProfileDetailsView();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <div>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Profile
          </div>
          <Form
            //For single update
            onFieldsChange={(field) => {
              setSchoolType(field[0].value);
            }}
            //For multiple updates
            // onFieldsChange={(changedFields) => {
            //   changedFields.forEach(field => {
            //     if (field.name[0] === 'schoolType') {
            //       setSchoolType(field.value);
            //     }
            //     // Handle other fields similarly if needed
            //   });
            // }}
            className="bg-white p-2"
            layout="vertical"
            onFinishFailed={(err) => console.log(err)}
            onFinish={(changedValues) => editProfileDetails(changedValues)}
            form={form}
            ref={formRef}
          >
            <Divider>Profile</Divider>
            <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-2">
              <Col span={12}>
                <Form.Item
                  name="userName"
                  label="
                  उपयोगकर्ता का नाम"
                  rules={[
                    {
                      required: true,
                      message: "कृपया उपयोगकर्ता का नाम दर्ज करें",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="mobileNumber"
                  label="
                  मोबाइल नंबर"
                  rules={[
                    {
                      required: true,
                      message: "कृपया मोबाइल नंबर दर्ज करें",
                    },
                  ]}
                >
                  <Input
                    disabled={true}
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    type="text"
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-2">
              <Col span={12}>
                <Form.Item
                  name="medium"
                  label="माध्यम"
                  rules={[
                    {
                      required: true,
                      message: "कृपया माध्यम दर्ज करें",
                    },
                  ]}
                >
                  <Select
                    placeholder="माध्यम"
                    style={{
                      width: "165%",
                    }}
                    options={["Hindi", "Urdu"].map((item) => ({
                      value: item,
                      label: item,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="PAN"
                  label="पैन नंबर"
                  rules={[
                    {
                      pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                      message: "पैन नंबर का प्रारूप सही नहीं है",
                    },
                  ]}
                >
                  <Input
                    onInput={(e) => {
                      e.target.value = e.target.value.toUpperCase();
                    }}
                    maxLength={10}
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-2">
              <Col span={12}>
                <Form.Item
                  name="U_Dise_Code"
                  label="यू डाइस कोड"
                  rules={[
                    {
                      required: true,
                      message: "कृपया यू डाइस कोड दर्ज करें",
                    },
                    {
                      pattern: /^\d{11}$/,
                      message: "यू डाइस कोड 11 अंकों का होना चाहिए",
                    },
                  ]}
                >
                  <Input
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    maxLength={11}
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="schoolName"
                  label="
                  स्कूल के नाम"
                  rules={[
                    {
                      required: true,
                      message: "कृपया स्कूल का नाम दर्ज करें",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-2">
              {/* <Col span={12}>
                {" "}
                <Form.Item
                  name="numberOfStudents"
                  label="छात्रों की संख्या"
                  rules={[
                    {
                      required: true,
                      message: "कृपया छात्रों की संख्या दर्ज करें",
                    },
                  ]}
                >
                  <Input
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    type="text"
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  name="block"
                  label="ब्लॉक"
                  rules={[
                    {
                      required: true,
                      message: "कृपया ब्लॉक नाम दर्ज करें",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="district"
                  label="ज़िला"
                  rules={[
                    {
                      required: true,
                      message: "कृपया जिला दर्ज करें",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    style={{
                      width: "165%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-2">
              <Col span={12}>
                <Form.Item
                  name="schoolType"
                  label="स्कूल का प्रकार"
                  rules={[
                    {
                      required: true,
                      message: "कृपया स्कूल का प्रकार दर्ज करें",
                    },
                  ]}
                >
                  <Select
                    placeholder="स्कूल का प्रकार"
                    style={{ width: "165%" }}
                    options={[
                      { value: "Primary", label: "Primary" },
                      { value: "Middle school", label: "Middle school" },
                    ]}
                    // onChange={handleSchoolTypeChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider>Other Details</Divider>
            {schoolType === "Primary" ? (
              <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-2">
                <Col span={12}>
                  <Form.Item
                    name="PrimaryClass_avsheshChawa"
                    label="अवशेष चावल(Primary)"
                    rules={[
                      {
                        required: true,
                        message: "कृपया अवशेष चावल दर्ज करें",
                      },
                    ]}
                  >
                    <Input
                      onInput={(e) => {
                        let value = e.target.value;
                        // Allow negative sign and numbers
                        value = value.replace(/[^-0-9.]/g, "");

                        // Ensure only one negative sign at the beginning
                        if (value.indexOf("-") !== value.lastIndexOf("-")) {
                          value =
                            value.substring(0, value.lastIndexOf("-")) +
                            "-" +
                            value.split("-").slice(1).join("");
                        }

                        // Ensure only one decimal point
                        const parts = value.split(".");
                        if (parts.length > 2) {
                          value = `${parts[0]}.${parts.slice(1).join("")}`;
                        }

                        e.target.value = value;
                      }}
                      type="text"
                      style={{
                        width: "165%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row gutter={16} className="grid grid-cols-1 sm:grid-cols-2">
                <Col span={12}>
                  <Form.Item
                    name="PrimaryClass_avsheshChawa"
                    label="अवशेष चावल(Primary)"
                    rules={[
                      {
                        required: true,
                        message: "कृपया अवशेष चावल दर्ज करें",
                      },
                    ]}
                  >
                    <Input
                      onInput={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^-0-9.]/g, "");
                        if (value.indexOf("-") !== value.lastIndexOf("-")) {
                          value =
                            value.substring(0, value.lastIndexOf("-")) +
                            "-" +
                            value.split("-").slice(1).join("");
                        }

                        const parts = value.split(".");
                        if (parts.length > 2) {
                          value = `${parts[0]}.${parts.slice(1).join("")}`;
                        }

                        e.target.value = value;
                      }}
                      type="text"
                      style={{
                        width: "165%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="MiddleClass_avsheshChawa"
                    label="अवशेष चावल(Middle)"
                    rules={[
                      {
                        required: true,
                        message: "कृपया अवशेष चावल दर्ज करें",
                      },
                    ]}
                  >
                    <Input
                      onInput={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^-0-9.]/g, "");

                        if (value.indexOf("-") !== value.lastIndexOf("-")) {
                          value =
                            value.substring(0, value.lastIndexOf("-")) +
                            "-" +
                            value.split("-").slice(1).join("");
                        }

                        // Ensure only one decimal point
                        const parts = value.split(".");
                        if (parts.length > 2) {
                          value = `${parts[0]}.${parts.slice(1).join("")}`;
                        }

                        e.target.value = value;
                      }}
                      type="text"
                      style={{
                        width: "165%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            <div>
              <Form.Item className="flex justify-center ">
                <Button htmlType="onSubmit">Save</Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default Profile;

