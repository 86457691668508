
import Login from "../Login";
import PublicAuth from "./PublicAuth";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth/>,
      children: [
        {
          path: "/",
          element: <Login />,
        }, 
       
      ],
    },
  ];
};
