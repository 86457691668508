import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from "antd";
import React from "react";
import dayjs from "dayjs";
import { addData, editData } from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";
import moment from "moment";

const AddRegister = ({ open, setOpen, apiCall, from, record }) => {
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleAdd = (valuesArray) => {
    let payload;
    if (from === "edit") {
      payload = {
        entryId: record._id,
        No_of_students: Number(valuesArray.No_of_students),
        Available_amount: Number(valuesArray.Available_amount ?? 0),
        Allotted_Rice: Number(valuesArray.Allotted_Rice ?? 0),
      };
      editData(payload)
        .then((res) => {
          if (res.status) {
            dispatchtoast(res.message);
            setOpen(false);
            apiCall();
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log(valuesArray.entry_date);
      payload = {
        entry_date: formatDate(valuesArray.entry_date),
        No_of_students: Number(valuesArray.No_of_students),
        classType: valuesArray.classType,
        Available_amount: Number(valuesArray.Available_amount ?? 0),
        Allotted_Rice: Number(valuesArray.Allotted_Rice ?? 0),
      };
      addData(payload)
        .then((res) => {
          if (res.status) {
            dispatchtoast(res.message);
            setOpen(false);
            apiCall();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  console.log(record);
  const disabledDate = (current) => {
    // Can not select dates before the start of the current month and after the end of the current month
    return (
      current &&
      (current < dayjs().startOf("month") || current > dayjs().endOf("month"))
    );
  };

  return (
    <div>
      <Modal
        title={from === "edit" ? "Edit Register Data" : "Add Register Entry"}
        open={open}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <Form
          className="bg-white p-6"
          layout="vertical"
          onFinishFailed={(err) => console.log(err)}
          onFinish={(changedValues) => handleAdd(changedValues)}
          initialValues={
            from === "edit"
              ? {
                  entry_date: moment(record.entry_date), // Assuming createdAt is a valid date string
                  No_of_students: record.No_of_students,
                  Available_amount: record.Available_amount,
                  classType: record.classType,
                  Allotted_Rice: record.Allotted_Rice,
                }
              : !record?.MiddelClassRegister
              ? {
                  classType: "PrimaryClass",
                }
              : ""
          }
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="तारीख"
                labelCol={{ span: 24 }}
                className="w-[80%]"
                rules={[
                  {
                    required: true,
                    message: "कृपया दिनांक चुनें",
                  },
                ]}
                name={"entry_date"}
              >
                <DatePicker
                  className="w-full"
                  placeholder="Date"
                  disabledDate={disabledDate}
                  // disabled={from === "edit" ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="No_of_students"
                label="उपस्थित छात्र/छात्राओं की संख्या"
                rules={[
                  {
                    required: true,
                    message: "कृपया उपस्थित छात्रों की संख्या दर्ज करें",
                  },
                ]}
              >
                <Input
                  placeholder="उपस्थित छात्रों की संख्या"
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  type="text"
                  style={{
                    width: "80%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="classType"
                label="कक्षा का प्रकार"
                rules={[
                  {
                    required: true,
                    message: "कृपया कक्षा का प्रकार दर्ज करें",
                  },
                ]}
              >
                <Select
                  disabled={
                    from === "edit"
                      ? true
                      : record?.MiddelClassRegister
                      ? false
                      : true
                  }
                  placeholder="कक्षा का प्रकार"
                  style={{
                    width: "80%",
                  }}
                  options={["PrimaryClass", "MiddleClass"].map((item) => ({
                    value: item,
                    label: item,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="Available_amount"
                label="उपलब्ध राशि"
                // rules={[
                //   {
                //     required: true,
                //     message: "कृपया उपलब्ध राशि दर्ज करें",
                //   },
                // ]}
              >
                <Input
                  placeholder="उपलब्ध राशि"
                  defaultValue="0"
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, "");
                    const parts = value.split(".");
                    if (parts.length > 2) {
                      e.target.value = `${parts[0]}.${parts[1]}`;
                    } else {
                      e.target.value = value;
                    }
                  }}
                  type="text"
                  style={{
                    width: "80%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="Allotted_Rice"
                label="प्राप्त चावल"
                // rules={[
                //   {
                //     required: true,
                //     message: "कृपया प्राप्त चावल दर्ज करें",
                //   },
                // ]}
              >
                <Input
                  placeholder="प्राप्त चावल"
                  onInput={(e) => {
                    const value = e.target.value.replace(/[^0-9.]/g, "");
                    const parts = value.split(".");
                    if (parts.length > 2) {
                      e.target.value = `${parts[0]}.${parts[1]}`;
                    } else {
                      e.target.value = value;
                    }
                  }}
                  defaultValue="0"
                  type="text"
                  style={{
                    width: "80%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Form.Item className="flex justify-center">
              <Button type="primary" htmlType="onSubmit">
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
export default AddRegister;
